import { Controller } from "@hotwired/stimulus";
import "summernote/dist/summernote-bs4";
import "summernote/dist/summernote-bs4.css";
// import "summernote/dist/summernote-lite";
// import "summernote/dist/summernote-lite.css";


export default class extends Controller {
  static targets = ["editor"];

  connect() {
    $(this.editorTarget).summernote({
      placeholder: "Write the help content here...",
      tooltip: false,
      tabsize: 2,
      height: 600,
      toolbar: [
        ["style", ["bold", "italic", "underline", "clear"]],
        ["para", ["ul", "ol", "paragraph"]],
        ["insert", ["link", "picture", "video"]],
      ]
    });
  }

  disconnect() {
    $(this.editorTarget).summernote("destroy");
  }
}
